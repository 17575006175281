<template>
  <div class="build-div" v-if="showDiv">
    <div class="title-div">
        {{tableData.name || '未知'}}
    </div>
    <div class="card">
        <div class="item background1" v-if="category == '2'">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总户数代表:</div>
                      <div class="num">{{tableData.total || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">已入驻户代表: {{tableData.num || 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.num && tableData.num > 0 ? ((tableData.num/tableData.total) * 100) > 100 ? 100 : ((tableData.num/tableData.total) * 100) :0" :stroke-color="['#41ABF7', '#ccd3f8']">
                       <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.num && tableData.num > 0 ? ((tableData.num/tableData.total) * 100) > 100 ? 100 : ((tableData.num/tableData.total) * 100) :0}}%</span>
                  </Progress>
              </div>
          </div>
        
    </div>
    <div class="center">
          <div class="desc">图像说明:</div>
          <div class="white">
              <div class="left"></div>
              <div class="right">
                  <div>{{tableData.total - tableData.num  ? tableData.total - tableData.num  : 0}}</div>
                  <div>未入驻</div>
              </div>
          </div>
          <div class="yellow">
              <div class="left"></div>
                <div class="right">
                    <div>{{tableData.num || 0}}</div>
                    <div>已入驻户代表</div>
                </div>
          </div>
      </div>
       <div class="bottom">
           <div class="bottom-child">
                <div class="child">
                    <div class="child-view">
                        <div class="build-view" v-for="(items,index) in tableData.childs" :key="index">
                            <template>
                                <div :class="items.nodeManager ?'item yellow':'item'">
                                    <div class="image">
                                        <img class="img" :src="buildimage" alt="" />
                                    </div>
                                    <div class="text">
                                        <div class="title">{{items.name}}</div>
                                        <div class="title2">{{items.nodeManager ? '已入驻' : '未入驻'}}</div>
                                        </div>
                                </div> 
                            </template>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import buildimage from '../../../../../public/images/group_icon.png'
export default {
    props:{
        tableData:{
            type:Array,
            default:()=>{
                return []
            }
        },
        isShow:{
            type:Number,
            default:()=>{
                return 0
            }
        }
    },
    data(){
        return{
            buildimage:buildimage,
            showDiv:true
        }
    },
    watch:{
        isShow(){
            this.showDiv = false
            this.$nextTick(()=>{
                this.showDiv = true
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .build-div{
        width: 100%;
        .title-div{
            font-size: 20px;
            font-weight: bold;
            padding-left: 20px;
        }
        .card{
            margin-top: 20px;
            width: 200px;
            text-align: center;
            .item{
                font-size: 16px;
                width: 25%;
                height: 100%;
                padding: 20px;
                
                .title{
                    text-align: center;
                    margin: 10px  ;
                }
            }
        }
         .center{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: flex-start;
            border-top: 1px solid rgb(228, 227, 227);
            border-bottom: 1px solid rgb(228, 227, 227);
            padding-top: 15px;
            margin: 20px 0;
            .desc,.white,.red,.yellow,.blue{
                margin-right: 20px;
                display: flex;
            }
            .desc{
                margin-left: 20px;
                font-size: 16px;
                line-height: 50px;
            }
            .white{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background: #cccccc;
                    margin-right: 10px;
                }
                .right{
                    text-align: center;
                }
            }
            .red{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: #55C794;
                }
                .right{
                    text-align: center;
                }
            }
            .yellow{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: #EE866B;
                }
                .right{
                    text-align: center;
                }
            }
            .blue{
                padding: 5px 0 ;
                .left{
                    width: 40px;
                    height: 40px;
                    border: 1px solid #ccc;
                    margin-right: 10px;
                    background-color: #33CCCC;
                }
                .right{
                    text-align: center;
                }
            }
        }
        .bottom{
        
        .bottom-view{
            display: flex;
            justify-content: flex-start;
            .left{
                    width: 180px;
                    word-break: wrap;
                    padding:10px 20px 20px 20px;
                    border: 1px solid #ccc;
                    margin-right: 20px;
                    height: 100px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-align: center;
                    &none{
                        background-color: #33CCCC;
                        color:white;
                    }
                }   
                .right{
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .item{
                        border: 1px solid #ccc;
                        margin: 0px 20px 20px 0;
                        width: 200px;
                        height: 100px;
                        position: relative;
                        user-select: none;
                        cursor: pointer;
                        .content{
                            position: absolute;
                            margin: 10px;
                            width: 180px;
                            height: 80px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            line-clamp: 4;
                            -webkit-box-orient: vertical;
                            text-align: center;
                        }
                    }
                    .red{
                        background-color: #33CCCC;
                        color: #ffffff;
                    }
                    .yellow{
                        background-color: #EE866B;
                        color: #ffffff;
                    }
                    .blue{
                        background-color: #33CCCC;
                        color: #ffffff;
                    }
                }
        }
        
    }
    }
    .card{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item{
            font-size: 16px;
            width: 380px;
            height: 160px;
            padding: 20px;
            color: #ffffff;
            border-radius: 8px;
            margin: 0 20px;
            .top-div{
                display: flex;
                justify-content: space-between;
                height:100px;
                .div-left{
                    .title{
                        opacity: 0.5;
                        font-size: 18px;
                        line-height: 32px;
                    }
                    .num{
                        font-size: 40px;
                        // line-height: 70px;
                        .span{
                            font-size: 16px;
                            line-height: 28px;
                            color: #FFFFFF;
                            opacity: 0.5;
                            padding-left: 20px;
                        }
                    }
                }
                .div-right{
                    font-size: 20px;
                    margin-right: 20px;
                    .num1{
                        padding-top: 10px;
                        // line-height: 32px;
                    }
                    .num2{
                        line-height: 32px;
                        margin-top: 20px;
                    }
                }
            }
            .bottom-div{
                height: 60px;
            }
        }
        .background1{
            background-image: linear-gradient(to right,#5770FB, #C577FF)
        }
        .background2{
            background-image: linear-gradient(to right,#40ACF7,#737CFF)
        }
        .background3{
            background-image: linear-gradient(to right,#ED6281,#F09D5E)
        }
        .background4{
            background-image: linear-gradient(to right,#3DB684,#95CF4B)
        }
    }
    .bottom-child{
        height: 100%;
        .child{
            color: #ffffff;           
           .top{
               width: 680px;
               height: 80px;
               border-radius:6px;
               display: flex;
               background:#CCCCCC;
               font-size: 20px;
               justify-content: flex-start;
               height: 80px;
               line-height: 80px;
               min-width: 680px;
               margin: 20px 0;
               padding-right: 0 20px;
           }
           .child-top{
               background: #55C794;
           }
           .name{
               padding-left: 20px;
           }
           .build{
               padding-left: 20px;
           }
           .builed{
               margin-left: 20px;
               margin-right: 20px;
           }
           .child-view{
               width: 100%;
               margin: 20px 20px 20px 0;
               display: flex;
                flex-wrap: wrap;
               .build-view{
                   width: 22%;
                   margin-right: 20px;
                   height: 176px;
                   background: #FDFFFF;
                   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                   opacity: 1;
                   border-radius: 6px;
                   cursor: pointer;
                   user-select: none;
                   color: black;
                   margin-bottom: 20px;
                   &:hover{
                       box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
                   }
                   .item{
                       background: #cccccc;
                       border-top-left-radius: 6px;
                       border-top-right-radius: 6px;
                       display: flex;
                       height: 72px;
                       padding: 10px 20px;
                   }
                   .yellow{
                       background: #EE866B;
                   }
                   .image{
                       margin-right: 5px;
                       .img{
                           width: 34px;
                           height: 34px;
                       }
                   }
                   .text{
                       .title{
                           font-size: 16px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                       .title2{
                           font-size: 12px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                    }
                    .text-view{
                        display: flex;
                        justify-content: flex-start;
                        padding-top: 20px;
                        .text-item{
                            width: 33.3%;
                            text-align: center;
                            .title{
                                font-size: 14px;
                                line-height: 21px;
                                color: #999999;
                            }
                            .num{
                                font-size: 14px;
                                line-height: 25px;
                                color: #393939;
                            }
                        }
                        
                    }
                    .precent{
                            height: 30px;
                            margin-left: 10px;
                        }
               }
               
           }
        }
    }
    /deep/.ivu-card{
        background: none;
    }
    /deep/.ivu-card-bordered{
        border: none;
        color: #ffffff;
    }
    /deep/.ivu-progress-text{
        color: #ffffff;
    }
    
</style>